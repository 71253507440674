import React, { useContext } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import lockIcon from '../../assets/svg/lock.svg'
import { ReactComponent as LoginIcon } from '../../assets/svg/login.svg'
import { ReactComponent as SignupIcon } from '../../assets/svg/signup.svg'
import UserProfile from '../../assets/svg/userProfile.svg'
import { ReactComponent as Logo } from '../../assets/svg/logo.svg'
import 'rsuite/dist/rsuite.min.css'
import AppContext from '../../Context'

export default function HomeHeader ({}) {
  const navigate = useNavigate()
  const [anchorEl, setAnchorEl] = React.useState(null)
  let userData = localStorage.getItem('userData')
  const { user, setUser } = useContext(AppContext)
  userData = JSON.parse(userData)
  const handleClick = event => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const logout = () => {
    localStorage.removeItem('token')
    localStorage.removeItem('user')
    setUser(null)
    navigate('/')
  }

  const open = Boolean(anchorEl)
  const id = open ? 'simple-popover' : undefined

  return (
      <header className='login_nav home_nav'>
        <div className={`container`}>
          <div className='col-12'>
            <div className='row justify-content-between'>
              <div className='d-flex align-items-center'>
                <Link to={'/'}>
                  <div className='d-flex align-items-center'>
                    <Logo style={{ width: 200, height: 70 }} />
                  </div>
                </Link>
              </div>

              <ul className='row align-items-center'>
                {user ? (
                  <>
                    <li>
                      <div className='listpool mr-4'>{user?.name}</div>
                    </li>
                    <li onClick={handleClick} className='listpool mr-4'>
                      <img src={UserProfile} className={'c-pointer'} />
                    </li>
                  </>
                ) : (
                  <>
                    <li>
                      <Link to={'/about-us'}>
                        <div className='listpool ml-5'>For Pharmacies </div>
                      </Link>
                    </li>
                    <li>
                      <Link to={'/contact-us'}>
                        <div className='listpool ml-5'>FAQ</div>
                      </Link>
                    </li>
                    <Link to={'/signup'} className='ml-3'>
                      <li>
                        <div className='listpool ml-4'>
                          <SignupIcon className='mr-1' />
                          Sign up
                        </div>
                      </li>
                    </Link>
                    <div className='vline' />
                    <Link to={'/login'}>
                      <li>
                        <div className='listpool ml-4'>
                          <LoginIcon className='mr-1' /> Log in
                        </div>
                      </li>
                    </Link>
                  </>
                )}
              </ul>
            </div>
          </div>
        </div>
      </header>
  )
}
