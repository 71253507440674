const BASE_URL = 'https://frosty-mouse-34712.botics.co/'

export const defaultConfig = {
  API_URL: BASE_URL
}

export const App = {
  config: defaultConfig
}

window.env = window.env || defaultConfig
App.config = { ...window.env }

export const API_URL = () => App.config.API_URL
