import { Input } from '@mui/material'
import React from 'react'
import Label from './Label'

export default function BasicInput ({
  backgroundColor,
  color,
  borderRadius,
  placeholder,
  multiline,
  prefix,
  border,
  labelNormal,
  postfix,
  label,
  inputWidth,
  value,
  onChange,
  name,
  type,
  select,
  selectOptions,
  height,
  startLabel
}) {
  return (
    <div className={startLabel ? 'basicInputStart' : 'basicInput'}>
      <Label text={label} basic fontnormal={labelNormal} />
      <div
        style={{
          width: inputWidth || '100%',
          display: 'flex',
          borderRadius: borderRadius || 0,
          backgroundColor: backgroundColor || '#fff',
          color: color || '#000',
          padding: '0 15px',
          height: multiline ? height || 150 : 45,
          justifyContent: 'space-between',
          border: border || '1px solid #A3A3A3'
        }}
      >
        <div className='d-flex' style={{ width: '100%' }}>
          {prefix}
          {multiline ? (
            <textarea
              type={type}
              placeholder={placeholder}
              onChange={value => onChange(name, value.target.value)}
              value={value}
              style={{
                border: 'none',
                backgroundColor: backgroundColor || '#fff',
                color: color || '#000',
                width: '100%'
              }}
            />
          ) : (
            <>
              {select ? (
                <select
                  value={value}
                  style={{
                    border: 'none',
                    backgroundColor: backgroundColor || '#fff',
                    color: color || '#000',
                    height: 40,
                    width: '100%'
                  }}
                  placeholder={placeholder}
                  onChange={value => onChange(name, value.target.value)}
                >
                  {selectOptions}
                </select>
              ) : (
                <input
                  type={type}
                  placeholder={placeholder}
                  onChange={value => onChange(name, value.target.value)}
                  value={value}
                  style={{
                    border: 'none',
                    backgroundColor: backgroundColor || '#fff',
                    color: color || '#000',
                    height: 40,
                    width: '100%'
                  }}
                />
              )}
            </>
          )}
        </div>
        {postfix}
      </div>
    </div>
  )
}
