// @ts-nocheck
/* eslint-disable no-empty-pattern */
import React, { useEffect, useState } from 'react'
import {
  AppButton,
  BasicInput,
  HomeHeader,
  Label,
  MainFooter
} from '../../components'
import { Grid, Typography, Checkbox } from '@mui/material'
import eyeIcon from '../../assets/svg/eye.svg'
import { Link, useNavigate } from 'react-router-dom'
import { signupUser } from '../../api/auth'
import AppContext from '../../Context'
import { useContext } from 'react'
import { COLORS } from '../../constants'
import { useSnackbar } from 'notistack'
import { ReactComponent as SignupIcon } from '../../assets/svg/signup.svg'
import { ReactComponent as ArrowRight } from '../../assets/svg/arrowRight.svg'
import ArrowUp from '../../assets/svg/arrowUp.svg'

export default function Signup ({}) {
  const { enqueueSnackbar } = useSnackbar()
  const navigate = useNavigate()
  const { user, setUser } = useContext(AppContext)
  const path = window.location.pathname
  const [state, setState] = useState({
    activeTab: 0,
    email: '',
    password: '',
    c_password: '',
    phone_number: '',
    age: '',
    loading: false,
    loadingSignup: false,
    checked: false
  })

  useEffect(() => {
    if (path) {
      handleChange('activeTab', path === '/signup' ? 0 : 1)
    }
    if (user) {
      navigate('/')
    }
  }, [path, user])

  const {
    full_name,
    last_name,
    email,
    password,
    loading,
    c_password,
    phone_number,
    checked,
    age
  } = state

  const handleChange = (key, value) => {
    setState(pre => ({
      ...pre,
      [key]: value
    }))
  }

  const handleSignup = async () => {
    try {
      handleChange('loadingSignup', true)
      const payload = {
        full_name,
        last_name,
        email,
        password,
        phone_number
      }
      const res = await signupUser(payload)
      localStorage.setItem('token', res?.data?.key)
      handleChange('loadingSignup', false)
      enqueueSnackbar(`Signup Successfully`, {
        variant: 'success',
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'right'
        }
      })
      navigate('/')
    } catch (error) {
      handleChange('loadingSignup', false)
      const errorText = Object.values(error?.response?.data)
      if (errorText.length > 0) {
        alert(`Error: ${errorText[0]}`)
      } else {
        alert(`Error: ${error}`)
      }
    }
  }

  return (
    <div>
      <HomeHeader />
      <section className=''>
        <div className='container divCenter loginContainer'>
          <Grid container justifyContent={'center'}>
            <Grid item xs={10} sm={10} md={6} lg={6} className=''>
              <div className='text-center mb-5'>
                <div className='text_primary mb-4 text-center font-24 font-bold'>
                  Sign up
                </div>
                <SignupIcon style={{ width: 100, height: 100 }} />
              </div>
              <BasicInput
                label={'Full Name'}
                value={full_name}
                name={'full_name'}
                onChange={handleChange}
                className='mb-3 mt-3'
                placeholder={'Enter Full Name'}
              />

              <BasicInput
                label={'Email'}
                value={email}
                name={'email'}
                onChange={handleChange}
                className='mb-3 mt-3'
                placeholder={'Enter Email'}
              />
              <BasicInput
                className='mb-4 mt-3'
                label={'Password'}
                value={password}
                type={'password'}
                name={'password'}
                onChange={handleChange}
                postfix={
                  <img src={eyeIcon} width={20} className={'c-pointer'} />
                }
                placeholder={'********'}
              />
              <BasicInput
                className='mb-4 mt-3'
                label={'Confirm password'}
                value={c_password}
                type={'password'}
                name={'c_password'}
                onChange={handleChange}
                postfix={
                  <img src={eyeIcon} width={20} className={'c-pointer'} />
                }
                placeholder={'********'}
              />
              <div className='d-flex align-items-center justify-content-between'>
                <div style={{ width: '55%' }}>
                  <BasicInput
                    label={'Phone'}
                    value={phone_number}
                    name={'phone_number'}
                    onChange={handleChange}
                    className='mb-3 mt-3'
                    placeholder={'Enter Phone'}
                  />
                </div>
                <div style={{ width: '42%' }}>
                  <BasicInput
                    label={'Age'}
                    type='date'
                    value={age}
                    name={'age'}
                    onChange={handleChange}
                    className='mb-3 mt-3'
                    placeholder={'Select Age'}
                  />
                </div>
              </div>
              <div className='d-flex align-items-center justify-content-between'>
                <div style={{ width: '40%' }}>
                  <BasicInput
                    label={'Gender'}
                    value={phone_number}
                    name={'phone_number'}
                    onChange={handleChange}
                    className='mb-3 mt-3'
                    placeholder={'Select Gender'}
                  />
                </div>
                <div style={{ width: '55%' }}>
                  <BasicInput
                    label={'Address'}
                    value={age}
                    name={'age'}
                    onChange={handleChange}
                    className='mb-3 mt-3'
                    placeholder={'Enter Address'}
                  />
                </div>
              </div>
              <div className='d-flex align-items-center justify-content-between'>
                <div style={{ width: '30%' }}>
                  <BasicInput
                    label={'City'}
                    value={phone_number}
                    name={'phone_number'}
                    onChange={handleChange}
                    className='mb-3 mt-3'
                    placeholder={'Enter City'}
                  />
                </div>
                <div style={{ width: '35%' }}>
                  <BasicInput
                    label={'State'}
                    value={age}
                    name={'age'}
                    onChange={handleChange}
                    className='mb-3 mt-3'
                    placeholder={'Enter State'}
                  />
                </div>
                <div style={{ width: '32%' }}>
                  <BasicInput
                    value={age}
                    name={'age'}
                    onChange={handleChange}
                    className='mb-3 mt-3'
                    placeholder={'Zip Code'}
                  />
                </div>
              </div>
              <div className='d-flex mb-4'>
                <div className='d-flex'>
                  <Label basic text={'Upload State id or driver´s license'} />
                  <img src={ArrowUp} width={20} className='mr-3' />
                </div>
                <div className='d-flex'>
                  <Label basic text={'Upload insurance'} />
                  <img src={ArrowUp} width={20} />
                </div>
              </div>
              <div className='rowBetween mb-4'>
                <div className='checkboxDiv mt-1'>
                  <Typography
                    variant='body2'
                    className='text_primary06 checkboxLabel'
                  >
                    Agreed to terms and conditions
                  </Typography>
                  <Checkbox
                    checked={checked}
                    onClick={() => handleChange('checked', !checked)}
                    className='checkbox1'
                    sx={{
                      color: 'rgba(201, 208, 216, 1)',
                      '&.Mui-checked': {
                        color: 'rgba(168, 124, 81, 1)'
                      }
                    }}
                  />
                </div>
                <AppButton
                  title={
                    <div>
                      Continue <ArrowRight />
                    </div>
                  }
                  className='font-bold font-20'
                  onClick={handleSignup}
                  loading={loading}
                  width={150}
                  height={40}
                  disabled={
                    !email ||
                    !password ||
                    !full_name ||
                    !last_name ||
                    !phone_number
                  }
                  borderRadius={30}
                  backgroundColor={COLORS.primary}
                  color={'#13065B'}
                />
              </div>
              <div className='d-flex align-items-center mb-4'>
                <Label basic text={'Already have an account?'} />
                <AppButton
                  title={'Login'}
                  className='font-bold font-20'
                  onClick={() => navigate('/login')}
                  loading={loading}
                  width={150}
                  height={40}
                  borderRadius={30}
                  backgroundColor={COLORS.primary}
                  color={'#13065B'}
                />
              </div>
              <div className='mt-4 text-center'>
                <Link
                  to={'/login'}
                  className='text-center font-18 text_primary'
                >
                  Your account number is
                </Link>
              </div>
              <div className='d-flex justify-content-center mb-4'>
                <div className='accountNumberBox'>00010002000</div>
              </div>
            </Grid>
          </Grid>
        </div>
      </section>
    </div>
  )
}
