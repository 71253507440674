// @ts-nocheck
/* eslint-disable no-empty-pattern */
import React, { useContext, useState } from 'react'
import {
  HomeHeader,
  AppButton,
  MainFooter,
  AppInput,
  AppSwitch,
  Label,
  BasicInput
} from '../../components'
import { Element } from 'react-scroll'
import Slider from 'react-slick'
import { Grid } from '@mui/material'
import { ReactComponent as FreeDelivery } from '../../assets/svg/freeDelivery.svg'
import { ReactComponent as OrderOnline } from '../../assets/svg/orderOnline.svg'
import { ReactComponent as SearchIcon } from '../../assets/svg/search.svg'
import { ReactComponent as Medication } from '../../assets/svg/medication.svg'
import { ReactComponent as YourInsurance } from '../../assets/svg/yourInsurance.svg'
import { ReactComponent as ExpeditedDelivery } from '../../assets/svg/expeditedDelivery.svg'
import { ReactComponent as Back } from '../../assets/svg/Back.svg'
import { ReactComponent as Search } from '../../assets/svg/searchicon.svg'
import { ReactComponent as House } from '../../assets/svg/House.svg'
import { ReactComponent as Book } from '../../assets/svg/Book.svg'
import { ReactComponent as Union } from '../../assets/svg/Union.svg'
import { ReactComponent as Previous } from '../../assets/svg/previous.svg'
import { ReactComponent as Approved } from '../../assets/svg/approved.svg'
import NeverMiss from '../../assets/images/neverMiss.png'
import { ReactComponent as EqualTo } from '../../assets/svg/equalTo.svg'
import { ReactComponent as Plus } from '../../assets/svg/Plus.svg'
import { useNavigate } from 'react-router-dom'
import AppContext from '../../Context'
import { COLORS } from '../../constants'
import ArrowUp from '../../assets/svg/arrowUp.svg'

export default function MainHome ({}) {
  const navigate = useNavigate()
  const { listRVS } = useContext(AppContext)
  const [state, setState] = useState({
    isSearched: false,
    isOrder: false,
    isGeneric: false
  })

  const { isSearched, isOrder, isGeneric } = state

  const handleChange = (key, value) => {
    setState(pre => ({ ...pre, [key]: value }))
  }

  const settings = {
    className: 'center',
    // centerMode: true,
    infinite: true,
    arrows: false,
    autoplay: true,
    slidesToShow: 3,
    speed: 500
  }

  const types = [
    {
      image: <SearchIcon />,
      title: 'Search',
      description:
        'Find which pharmacies near you that currently have your prescription.'
    },
    {
      image: <OrderOnline />,
      title: 'Order Online',
      description:
        'Order online and check out securely. Choose pickup or delivery.'
    },
    {
      image: <FreeDelivery />,
      title: 'Get Free Delivery',
      description:
        'Pay online and check out securely. Choose pickup or delivery.'
    }
  ]

  const types1 = [
    {
      image: <Medication className='nevermiss' />,
      title: 'The medication you Need'
    },
    {
      image: <Plus className='mt-5' />
    },
    {
      image: <YourInsurance className='nevermiss' />,
      title: ' Your usual insurance copay'
    },
    {
      image: <Plus className='mt-5' />
    },
    {
      image: <ExpeditedDelivery className='nevermiss' />,
      title: 'Expedited delivery'
    },
    {
      image: <EqualTo className='mt-5' />
    },
    {
      image: <img src={NeverMiss} className='nevermiss' />,
      title: 'Never Miss Your  Prescriptions'
    }
  ]
  const types2 = [
    {
      video_link:
        'https://res.cloudinary.com/zaafir-solutions/video/upload/v1666840184/y2mate.com_-_TajdareHaram_Atif_Aslam_Coke_Studio_Season_8_480p_jlk5py.mp4',
      title:
        'Muy cercs esci sit ut asped et ium nis at odis mil ipsam quo expla deliqui occullorro es adi dolorror acepudis quis destotaquis rae quo bla volut poresti aute nonsed eatque'
    },
    {
      video_link:
        'https://res.cloudinary.com/zaafir-solutions/video/upload/v1666840184/y2mate.com_-_TajdareHaram_Atif_Aslam_Coke_Studio_Season_8_480p_jlk5py.mp4',
      title:
        'Muy cercs esci sit ut asped et ium nis at odis mil ipsam quo expla deliqui occullorro es adi dolorror acepudis quis destotaquis rae quo bla volut poresti aute nonsed eatque'
    },
    {
      video_link:
        'https://res.cloudinary.com/zaafir-solutions/video/upload/v1666840184/y2mate.com_-_TajdareHaram_Atif_Aslam_Coke_Studio_Season_8_480p_jlk5py.mp4',
      title:
        'Muy cercs esci sit ut asped et ium nis at odis mil ipsam quo expla deliqui occullorro es adi dolorror acepudis quis destotaquis rae quo bla volut poresti aute nonsed eatque'
    },
    {
      video_link:
        'https://res.cloudinary.com/zaafir-solutions/video/upload/v1666840184/y2mate.com_-_TajdareHaram_Atif_Aslam_Coke_Studio_Season_8_480p_jlk5py.mp4',
      title:
        'Muy cercs esci sit ut asped et ium nis at odis mil ipsam quo expla deliqui occullorro es adi dolorror acepudis quis destotaquis rae quo bla volut poresti aute nonsed eatque'
    }
  ]

  const drugTypes = [
    {
      title: 'Erectile Dysfunction',
      description: 'Viagra (Generic) + 2 more',
      price: '19.95',
      quantity: '10'
    },
    {
      title: 'Hair Loss',
      description: 'Propecia (Generic) ',
      price: '19.95',
      quantity: '10'
    },
    {
      title: 'Birth Control',
      description: 'Sprintec (Generic) + 250 more',
      price: '19.95',
      quantity: '10'
    },
    {
      title: 'Cold Sores',
      description: 'Valtrex (Generic) ',
      price: '19.95',
      quantity: '10'
    },
    {
      title: 'High Colesterol',
      description: 'Lipitor (Generic) + 8 more',
      price: '19.95',
      quantity: '10'
    }
  ]

  const handleSearch = () => {
    navigate('/recommandation')
  }

  return (
    <div>
      <HomeHeader />
      {isSearched ? (
        <>
          <div className='container searchedLanding'>
            <Back onClick={() => handleChange('isSearched', false)} />
          </div>
          {!isOrder && (
            <section className='generic'>
              <div className=''>
                <div className='paper'>
                  <div className='d-flex'>
                    <div className='propecia'>Propecia (Generic)</div>
                    <div className='genericPara'>
                      is used to treat male pattern baldness (androgenetic
                      alopecia) at the crown and in the middle of the scalp. It
                      should be used by adult men only.This medication works by
                      decreasing the amount of a natural body hormone (DHT).
                      Decreasing the amount of DHT leads to …{' '}
                      <span className='showmore'>Show more</span>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          )}
          <div className='headingText text-center mb-1'>
            {isOrder ? 'Your order' : 'Give us more details'}
          </div>
          {isOrder && (
            <div className='headingText text-center mt-4 mb-1'>Status</div>
          )}
          {!isOrder && (
            <div className='align-center mt-4 mb-4'>
              <div className='text_primary font-16 mr-4'>
                Show generic options
              </div>
              <AppSwitch
                onClick={() => handleChange('isGeneric', !isGeneric)}
                value={isGeneric}
              />
            </div>
          )}
          {!isOrder && isGeneric && (
            <div className='container d-flex justify-content-center mb-5'>
              <div className='width80'>
                <div className='paper'>
                  <div className='d-flex'>
                    <div className='widthHalf'>
                      <BasicInput
                        className='mb-4 mt-3'
                        label={'Generic'}
                        select
                        labelNormal
                        selectOptions={
                          <>
                            <option value={''}>Select Generic</option>
                            <option value={'ATORVASTASTI'}>ATORVASTASTI</option>
                          </>
                        }
                        // value={password}
                        name={'password'}
                        onChange={handleChange}
                        placeholder={''}
                      />
                    </div>
                    <div className='widthHalf'>
                      <BasicInput
                        className='mb-4 mt-3'
                        label={'Form'}
                        labelNormal
                        // value={password}
                        name={'password'}
                        onChange={handleChange}
                        placeholder={''}
                      />
                    </div>
                  </div>
                  <div className='d-flex mt-4'>
                    <div className='widthHalf'>
                      <BasicInput
                        className='mb-4 mt-3'
                        label={'Strenght'}
                        select
                        labelNormal
                        selectOptions={
                          <>
                            <option value={''}>Select Strenght</option>
                            <option value={'10 MG'}>10 MG</option>
                            <option value={'20 MG'}>20 MG</option>
                            <option value={'40 MG'}>40 MG</option>
                            <option value={'80 MG'}>80 MG</option>
                          </>
                        }
                        // value={password}
                        name={'password'}
                        onChange={handleChange}
                        placeholder={''}
                      />
                    </div>
                    <div className='widthHalf'>
                      <BasicInput
                        className='mb-4 mt-3'
                        label={'Quantity'}
                        select
                        labelNormal
                        selectOptions={
                          <>
                            <option value={''}>Select Quantity</option>
                            <option value={'30 Tablets'}>30 Tablets</option>
                            <option value={'45 Tablets'}>45 Tablets</option>
                            <option value={'60 Tablets'}>60 Tablets</option>
                            <option value={'90 Tablets'}>90 Tablets</option>
                            <option value={'100 Tablets'}>100 Tablets</option>
                          </>
                        }
                        // value={password}
                        name={'password'}
                        onChange={handleChange}
                        placeholder={''}
                      />
                    </div>
                  </div>
                  <div className='d-flex align-items-center ml-5 mt-4'>
                    <div className='widthHalf'>
                      <BasicInput
                        className='mb-4 mt-3'
                        label={'Custom quantity'}
                        labelNormal
                        // value={password}
                        startLabel
                        inputWidth={150}
                        name={'password'}
                        onChange={handleChange}
                        placeholder={''}
                      />
                    </div>
                    <div className=' ml-5' style={{ width: '30%' }}>
                      <BasicInput
                        className='mb-4 mt-3 '
                        startLabel
                        inputWidth={50}
                        type={'number'}
                        label={'Day´s Supply'}
                        labelNormal
                        // value={password}
                        name={'password'}
                        onChange={handleChange}
                        placeholder={''}
                      />
                    </div>
                    <div className='updateButton'>Update</div>
                  </div>
                </div>
              </div>
            </div>
          )}
          {!isOrder && (
            <>
              <div className='headingText text-center mb-1'>
                List of pharmacies near you that have your prescription in
                stock"
              </div>
              <div className='text_primary text-center font-16'>
                Find pharmacies near you that have your prescription in stock.
                Order Online and Get Same Day Delivery
              </div>
              <div className='align-center'>
                <div className='text_primary font-16'>
                  Showing pharmacies near:
                </div>
                <div className='d-flex justify-content-center mb-4'>
                  <div className='showPharBox'>
                    who have your prescription in stock
                  </div>
                </div>
              </div>

              <div className='d-flex justify-content-center mb-4'>
                <div className='d-flex align-items-center'>
                  <div className='font-18 ml-2 mr-2'>1201 Avenue Z. NY</div>
                  <img src={ArrowUp} width={20} className='mr-3' />
                </div>
                <div className='d-flex align-items-center ml-5'>
                  <div className='font-bold font-18'>Filter</div>
                  <div className='font-18 ml-2 mr-2'>All</div>
                  <img src={ArrowUp} width={20} />
                </div>
                <div className='d-flex align-items-center ml-4'>
                  <div className='font-bold font-18'>Sort:</div>
                  <div className='font-18 ml-2 mr-2'>Price</div>
                  <img src={ArrowUp} width={20} />
                </div>
              </div>
              <div className='container d-flex justify-content-center mt-5 mb-5'>
                <div className='width80'>
                  <div className='text_primary width80 mb-5 font-16'>
                    Showing 5 of 48 pharmacies
                  </div>
                  {[0, 0, 0].map((item, index) => (
                    <div
                      className='paper mb-4 c-pointer'
                    >
                      <Grid container>
                        <Grid container item md={6} xs={12}>
                          <House />
                          <div className='ml-3 mt-4'>
                            <div className='font-18 font-bold' onClick={() => navigate('/order-details/123')}>
                              Medicine Shoppe
                            </div>
                            <div>1201 Avenue Z. NY(201)346-9202</div>
                            <div className='font-18 font-bold'>0.5 mi away</div>
                            <div>View on map</div>
                          </div>
                        </Grid>
                        <Grid item md={3} xs={12}>
                          <div>
                            <Book />
                            <div className='font-18 font-bold'>Status</div>
                            <div>3 units in stock</div>
                          </div>
                        </Grid>
                        <Grid item md={3} xs={12}>
                          <div>
                            <div className='d-flex align-items-center'>
                              <Union />
                              <div className='font-24 ml-5 font-bold'>10</div>
                            </div>
                            <div className='mt-2'>Without insurance</div>
                            <AppButton
                              title={'Order now'}
                              className='font-bold mt-3 font-18'
                              onClick={() => handleChange('isOrder', true)}
                              borderRadius={30}
                              width={150}
                              height={40}
                              backgroundColor={COLORS.primary}
                              color={'#13065B'}
                            />
                          </div>
                        </Grid>
                      </Grid>
                    </div>
                  ))}
                </div>
              </div>
            </>
          )}
          {isOrder && (
            <div className='container d-flex justify-content-center mt-5 mb-5'>
              <div className='width80'>
                <div className='paper mb-4'>
                  <Grid container alignItems={'center'}>
                    <Grid container item md={6} xs={12}>
                      <Approved />
                      <div className='ml-3 mt-5'>
                        <div className='font-24 font-bold'>Approved</div>
                        <div className='font-24'>by Pharmacy</div>
                      </div>
                    </Grid>
                    <Grid item md={3} xs={12}>
                      <div className='d-flex align-items-center'>
                        <Union />
                        <div className='font-36 ml-3 font-bold'>10</div>
                      </div>
                    </Grid>
                    <Grid container alignItems={'center'} item md={3} xs={12}>
                      <AppButton
                        title={'Pay'}
                        className='font-bold mt-3 font-18'
                        onClick={() => handleChange('isOrder', true)}
                        borderRadius={30}
                        width={150}
                        height={40}
                        backgroundColor={COLORS.primary}
                        color={'#13065B'}
                      />
                      <img src={ArrowUp} width={20} className='ml-3 mt-3' />
                    </Grid>
                  </Grid>
                  <Grid container justifyContent={'flex-end'}>
                    <Previous
                      className='c-pointer'
                      onClick={() => handleChange('isOrder', false)}
                    />
                  </Grid>
                </div>
              </div>
            </div>
          )}
        </>
      ) : (
        <>
          <Element name='Element1' className='element height75Mobile'>
            <section className='top_banner'>
              <div className='container height100 d-flex  justify-content-center align-items-center'>
                <Grid container>
                  <Grid item xs={12}>
                    {/* <div className='text-left'> */}
                    <p
                      className={
                        'text_primary text-center font-60 font-weight-bold'
                      }
                    >
                      Stop Calling Dozens of Pharmacies to Find Your
                      Prescription
                    </p>
                    <p className={'description'}>
                      Find pharmacies near you that have your prescription in
                      stock. Order Online and Get Same Day Delivery
                    </p>
                    <div className='searchBox'>
                      <AppInput
                        backgroundColor={'transparent'}
                        placeholder='Search by drug name (Eg. Propecia)”'
                        border={'none'}
                        prefix={<Search className='searchIcon' />}
                      />
                      <div className='vline' />
                      <AppInput
                        backgroundColor={'transparent'}
                        className='zipcode'
                        placeholder='Enter your zip code'
                        border={'none'}
                      />
                      <AppButton
                        title={'Find my prescription'}
                        className={'findmy'}
                        onClick={() => handleChange('isSearched', true)}
                        width={250}
                        height={40}
                        color={COLORS.white}
                        backgroundColor={COLORS.primary}
                      />
                    </div>
                    {/* </div> */}
                  </Grid>
                </Grid>
              </div>
            </section>
          </Element>

          <section className='howitwork'>
            <div className='container'>
              <div className='paper'>
                <div className='justify-content-center'>
                  <h1 className='font-24 text_primary mb-2 text-center'>
                    How FMT Works
                  </h1>
                </div>
                <div className='iframeVideoDiv'>
                  <iframe
                    src='https://www.youtube.com/embed/E7wJTI-1dvQ'
                    frameborder='0'
                    className='iframeVideo'
                    allow='autoplay; encrypted-media'
                    allowfullscreen
                    title='video'
                  />
                </div>
              </div>
            </div>
          </section>
          <section className='howitwork'>
            <div className='container'>
              <div className='paper'>
                <div className='justify-content-center'>
                  <h1 className='font-24 text_primary mb-2 text-center'>
                    Drugs Currently in Short Supply
                  </h1>
                </div>
                <Grid spacing={3} container>
                  <Grid item xs={12} container justifyContent={'space-between'}>
                    {drugTypes?.map((item, index) => (
                      <Grid key={index} sx={{ textAlign: 'center' }}>
                        <div className='mb-5'>
                          <div className='font-18 font-bold text_grey'>
                            {item.title}
                          </div>
                          <div className='text_grey mb-5 font-14'>
                            {item.description}
                          </div>
                        </div>
                        <div className='mt-5 d-flex align-items-center'>
                          <div>
                            <div className='font-18 font-bold text_grey'>
                              ${item?.price}
                            </div>
                            <div className='text_grey mb-2 font-14'>
                              {item.quantity} tablets
                            </div>
                          </div>
                          <div className='learnmoreHome'>Learn more</div>
                        </div>
                      </Grid>
                    ))}
                  </Grid>
                </Grid>
              </div>
            </div>
          </section>
          <section className='howitwork'>
            <div className='container'>
              <div className='paper'>
                <div className='justify-content-center'>
                  <h1 className='font-24 text_primary mb-5 text-center'>
                    3 Easy Steps to Find and Get Your Prescriptions
                  </h1>
                </div>
                <Grid spacing={3} justifyContent='space-around' container>
                  {types?.map((item, index) => (
                    <Grid
                      key={index}
                      item
                      md={3}
                      sm={6}
                      xs={12}
                      className='text-center'
                      direction={'column'}
                      alignItems='center'
                      // onClick={() => navigate(`/rv/${rv?.id}`)}
                    >
                      <div className='font-24 text_primary font-bold text-center mb-4'>
                        {item.title}
                      </div>
                      {item.image}
                      <div className='text_primary text-center mt-4'>
                        {item.description}
                      </div>
                    </Grid>
                  ))}
                </Grid>
              </div>
            </div>
          </section>
          <section className='howitwork'>
            <div className='container'>
              <div className='paper'>
                <div className='justify-content-center'>
                  <h1 className='font-24 text_primary mb-5 text-center'>
                    Using LomyRx is Free. Just Pay Your Copay
                  </h1>
                </div>
                <Grid spacing={3} container justifyContent={'space-around'}>
                  {types1?.map((item, index) => (
                    <div
                      key={index}
                      container
                      // onClick={() => navigate(`/rv/${rv?.id}`)}
                    >
                      <div className='text-center'>
                        {item.image}
                        {item.title && (
                          <div className='font-18 text_primary font-bold text-center mt-4'>
                            {item.title}
                          </div>
                        )}
                      </div>
                    </div>
                  ))}
                </Grid>
              </div>
            </div>
          </section>
          <section className='howitwork'>
            <div className='container'>
              <div className='paper'>
                <div className='justify-content-center'>
                  <h1 className='font-24 text_primary mb-5 text-center'>
                    What people are saying about FMT
                  </h1>
                </div>
                <Slider {...settings}>
                  {/* <Grid spacing={3} container> */}
                  {types2?.map((item, index) => (
                    <div key={index} className='videoBox'>
                      <video
                        className='video_link'
                        width='320'
                        height='240'
                        controls
                      >
                        <source src={item.video_link} type='video/mp4' />
                        <source src={item.video_link} type='video/ogg' />
                      </video>
                      <div className='font-14 text_primary text-justify mt-4'>
                        {item.title}
                      </div>
                    </div>
                  ))}
                  {/* </Grid> */}
                </Slider>
              </div>
            </div>
          </section>
        </>
      )}
      {!isSearched && <MainFooter />}
    </div>
  )
}
