import React from 'react'

export default function Label ({ text, basic, fontnormal }) {
  return (
    <p
      style={{
        fontWeight: fontnormal ? 'normal' : 'bold',
        marginRight: basic ? 20 : 0,
        minWidth: basic ? '25%' : '100%'
      }}
      className='font-18 text_primary mb-2'
    >
      {text}
    </p>
  )
}
