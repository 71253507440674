import React from 'react'
import { Divider, Grid } from '@mui/material'
import { Link } from 'react-router-dom'
import { ReactComponent as TwitterIcon } from '../../assets/svg/twitter.svg'
import { ReactComponent as LinkedinIcon } from '../../assets/svg/linkedin.svg'
import { ReactComponent as FacebookIcon } from '../../assets/svg/facebook.svg'
import { ReactComponent as InstagramIcon } from '../../assets/svg/instagram.svg'

export default function MainFooter () {
  let date = new Date().getFullYear()
  return (
    <div>
      {/* <!--Main footer--> */}
      <footer className='common_footer'>
        <div className='container'>
          <Grid container justifyContent={'space-between'}>
            <Grid
              item
              xs={12}
              md={5}
              className='mb-2 mt-2 d-flex font-bold align-items-center text-center'
            >
              <div className='mr-5 c-pointer'>
                <Link className='text-white font-16' to={'/terms-conditions'}>
                  Terms and conditions
                </Link>
              </div>
              <div className='c-pointer mr-5'>
                <Link className='text-white font-16' to={'/privacy-policy'}>
                  Privacy policy
                </Link>
              </div>
            </Grid>
            <Grid item xs={12} md={7} container alignItems={'center'}>
              <div className='text-white font-bold font-16 c-pointer'>Contact</div>
              <FacebookIcon className='ml-5 footerSocialIcon' />
              <TwitterIcon className='ml-5 footerSocialIcon' />
              <LinkedinIcon className='ml-5 footerSocialIcon' />
              <InstagramIcon className='ml-5 footerSocialIcon' />
            </Grid>
          </Grid>
        </div>
      </footer>
    </div>
  )
}
