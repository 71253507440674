// @ts-nocheck
/* eslint-disable no-empty-pattern */
import React, { useEffect, useState } from 'react'
import { AppButton, BasicInput, HomeHeader } from '../../components'
import { Grid } from '@mui/material'
import eyeIcon from '../../assets/svg/eye.svg'
import { Link, useNavigate } from 'react-router-dom'
import { loginUser } from '../../api/auth'
import AppContext from '../../Context'
import { useContext } from 'react'
import { COLORS } from '../../constants'
import { useSnackbar } from 'notistack'
import { ReactComponent as SignupIcon } from '../../assets/svg/signup.svg'

export default function Login ({}) {
  const navigate = useNavigate()
  const { enqueueSnackbar } = useSnackbar()
  const { user, setUser, _getProfile } = useContext(AppContext)
  const path = window.location.pathname
  const [state, setState] = useState({
    activeTab: 0,
    email: '',
    password: '',
    emailSignup: '',
    passwordSignup: '',
    loading: false,
    loadingSignup: false,
    checked: false
  })

  useEffect(() => {
    if (path) {
      handleChange('activeTab', path === '/signup' ? 0 : 1)
    }
    if (user) {
      navigate('/')
    }
  }, [path, user])

  const { email, password, loading, checked } = state

  const handleChange = (key, value) => {
    setState(pre => ({
      ...pre,
      [key]: value
    }))
  }

  const handleLogin = async () => {
    try {
      handleChange('  ', true)
      const payload = {
        username: email,
        password
      }
      const res = await loginUser(payload)
      localStorage.setItem('token', res?.data?.token)
      localStorage.setItem('user', JSON.stringify(res?.data?.user))
      handleChange('loading', false)
      _getProfile()
      setUser(res?.data?.user)
      enqueueSnackbar(`Login Successfully`, {
        variant: 'success',
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'right'
        }
      })
      navigate('/dashboard/profile')
    } catch (error) {
      handleChange('loading', false)
      const errorText = Object.values(error?.response?.data)
      if (errorText.length > 0) {
        alert(`Error: ${errorText[0]}`)
      } else {
        alert(`Error: ${error}`)
      }
    }
  }

  return (
    <div>
      <HomeHeader />
      <section className='bg-white'>
        <div className='container divCenter loginContainer'>
          <Grid container justifyContent={'center'}>
            <Grid item xs={10} sm={10} md={6} lg={6} className='text-center'>
              <div className='text-center mb-5'>
                <div className='text_primary mb-4 text-center font-24 font-bold'>
                  Log in
                </div>
                <SignupIcon style={{ width: 100, height: 100 }} />
              </div>
              <BasicInput
                label={'Email address'}
                value={email}
                name={'email'}
                onChange={handleChange}
                className='mb-3 mt-3'
                placeholder={'Enter Username'}
              />
              <BasicInput
                className='mb-4 mt-3'
                label={'Password'}
                value={password}
                type={'password'}
                name={'password'}
                onChange={handleChange}
                postfix={
                  <img src={eyeIcon} width={20} className={'c-pointer'} />
                }
                placeholder={'********'}
              />
              <div className='d-flex mb-4'>
                <div style={{ minWidth: '29%' }} />
                <div
                  className='c-pointer'
                  onClick={() => navigate('/forgot-password')}
                >
                  <p className='text_secondary'>Forgot Password?</p>
                </div>
              </div>
              <AppButton
                title={'Login'}
                onClick={handleLogin}
                loading={loading}
                disabled={!email || !password}
                borderRadius={30}
                className='font-bold font-18'
                width={100}
                height={40}
                backgroundColor={COLORS.primary}
                color={'#13065B'}
              />
              <div className='mt-4 mb-4 text-center'>
                <Link
                  to={'/signup'}
                  className='text-center font-20  text_primary font-bold'
                >
                  Don´t have an account?
                </Link>
              </div>
              <AppButton
                title={'Sign up'}
                className='font-bold font-18'
                onClick={() => navigate('/signup')}
                borderRadius={30}
                width={100}
                height={40}
                backgroundColor={COLORS.primary}
                color={'#13065B'}
              />
            </Grid>
          </Grid>
        </div>
      </section>
    </div>
  )
}
