// @ts-nocheck
/* eslint-disable no-empty-pattern */
import React, { useEffect, useState } from 'react'
import { AppInput, HomeHeader, AppButton, Loader } from '../../components'
import {
  Divider,
  Grid,
  Paper,
  Modal,
  Box,
  TextField,
  Radio,
  FormControlLabel,
  RadioGroup,
  FormControl
} from '@mui/material'
import { useNavigate, useParams } from 'react-router-dom'

export default function OrderDetails ({}) {
  const navigate = useNavigate()
  const { id } = useParams()
  const [state, setState] = useState({
    loading: false
  })

  const { loading } = state

  const handleChange = (key, value) => {
    setState(pre => ({ ...pre, [key]: value }))
  }

  if (loading) {
    return <Loader />
  }

  return (
    <div>
      <HomeHeader />
      <div className='headingText text-center mb-1 mt-5'>{'Pharmacy Details'}</div>
      <div className='orderNow text-center mb-1'>
        {'Order Now Before Your Prescription is Sold Out!'}
      </div>
    </div>
  )
}
